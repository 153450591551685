import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundMobile from '../../assets/img/samsung-text-background-mobile.png'
import BackgroundDesktop from '../../assets/img/samsung-text-background-desktop.png'

export const Section = styled.section`
  height: 432px;

  background-image: url(${BackgroundMobile});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;

  @media ${device.tablet} {
    background-image: url(${BackgroundDesktop});
    height: 455px;
  }

  @media ${device.desktopLG} {
    height: 510px;
  }

  @media ${device.desktopXL} {
    height: 654px;
  }

  .btn {
    height: 48px !important;

    @media ${device.tablet} {
      width: 462px !important;
      max-width: 462px !important;
    }
  }
`
