import React from 'react'

import { Section } from './styles'

const GarantaOFoturo = () => {
  return (
    <Section className='bg-grayscale--100 pt-5 pb-5 pt-md-0'>
      <div className='container h-100'>
        <div className='row h-100'>
          <div className='col-12 text-center'>
            <div className='d-flex flex-column justify-content-between h-100'>
              <div>
                <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-grayscale--500 font-sora fw-500 mb-4'>
                  Garanta o futuro agora
                </h2>
                <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-20 fs-xl-32 lh-xl-40 lh-xl-28 text-grayscale--500'>
                  O melhor lugar pra <span className='fw-600'>comprar o Samsung Galaxy S24</span> é no Shopping do Inter
                </p>
              </div>

              <div>
                <a href='https://intergo.app/ecba8bf8' target='blank' className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none'>
                  Comprar Samsung Galaxy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GarantaOFoturo
