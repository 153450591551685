import styled from 'styled-components'

export const Wrapper = styled.div`

  h1, h2, h3{
    font-family: 'Sora';
  }

  p{
    font-weight: 400;
  }
`
