import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './styles'

import pageContext from './pageContext.json'
import GarantaOFoturo from './sections/GarantaOFoturo/_index'
import HeroV2 from './sections/HeroV2/_index'

const BlackFriday = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <HeroV2 />
        <GarantaOFoturo />
      </Layout>
    </Wrapper>
  )
}
export default BlackFriday
