import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import LogoSamsung from '../../assets/img/logo-samsung.png'
import BannerHeroSamsungS24 from '../../assets/img/banner-hero-samsung-s24.png'

import { Section } from './styles'

function HeroV2 () {
  return (
    <Section className='bg-grayscale--100 pt-5 pb-5 pb-md-0' id='hero'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 px-0'>
            <span className='fs-16 lh-20 fs-md-24 lh-md-30 text-grayscale--500 text-center d-block'>Garanta agora o lançamento</span>
            <div className='d-md-flex justify-content-md-center'>
              <img src={LogoSamsung} alt='' />
            </div>
            <h1 className='fs-32 lh-60 fs-md-40 fs-lg-48 text-grayscale--500 text-center'>
              <span className='fw-600'>Galaxy S24</span> <span className='fw-300'>com até</span>
              <span className='fw-600 text-orange--extra d-block'>20% de cashback</span>
            </h1>
            <div className='d-none d-md-flex justify-content-md-center'>
              <div className='horizontal-line d-flex justify-content-center'>
                <div className='vertical-line' />
              </div>
            </div>
            <div className='d-md-flex justify-content-md-center'>
              <a href='https://intergo.app/ecba8bf8' target='blank'>
                <ImageWebp
                  pathSrc={BannerHeroSamsungS24}
                  altDescription='Três celulares samsung galaxy s24 nas cores lilás, prata e dourado'
                  className='text-center'
                  arrayNumbers={[ 324, 682, 825, 986 ]}
                  arrayNumbersHeight={[ 156, 350, 375, 430 ]}
                />

              </a>
            </div>
            <span className='fs-14 lh-17 fs-md-20 lh-md-26 fs-lg-24 lh-lg-30 fw-300 f-sora text-grayscale--500 text-center d-block mt-5'>A nova era dos smartphones já chegou no Shopping do Inter! Receba super cashback no seu <span className='fw-600'>novo Samsung Galaxy.</span></span>
            <div className='d-md-flex justify-content-md-center'>
              <a href='https://intergo.app/ecba8bf8' target='blank' className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none mt-5'>
                Aproveitar agora
              </a>
            </div>
            <div className='d-none d-md-flex justify-content-md-center'>
              <div className='d-flex justify-content-center py-5'>
                <div className='vertical-line-only' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroV2
