import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  @media ${device.tablet} {
    background-size: 420px;
  }

  @media ${device.desktopLG} {
    background-size: 502px;
  }

  @media ${device.desktopXL} {
    background-size: 627px;
  }

  @media ${device.desktopXXXL} {
    background-size: 682px;
    background-position-x: 142px;
  }

  .btn {
    height: 48px !important;

    @media ${device.tablet} {
      width: 462px !important;
      max-width: 462px !important;
    }

    @media ${device.desktopXL} {
      width: 286px !important;
      max-width: 286px !important;
    }
  }

  .horizontal-line {
    width: 100%;
    border-top: 1px solid ${grayscale[500]};

    @media ${device.tablet} {
      width: 50%;
    }
  }

  .vertical-line {
    height: 70px;
    width: 1px;
    background-color: ${grayscale[500]};

    @media ${device.tablet} {
      height: 40px;
    }
  }

  .vertical-line-only {
    height: 70px;
    width: 1px;
    background-color: ${grayscale[500]};
  }
`
